import React from 'react'
import { Authenticator } from '@aws-amplify/ui-react'

import './App.css'
import { MyRoutes } from './MyRoutes'
import { ThemeProvider } from '@aws-amplify/ui-react';

import * as clientLib from '@mytmpvpn/mytmpvpn-client/client'
import { MYTMPVPN_APIURL } from './appConfig'

function App() {
  const [client, setClient] = React.useState<any>(new clientLib.MyTmpVpnClient(MYTMPVPN_APIURL))

  return (
    <Authenticator.Provider>
      <ThemeProvider>
        <MyRoutes client={client} />
      </ThemeProvider>
    </Authenticator.Provider >
  )
}

export default App
