import React from 'react'

import { Text, View, Flex, Loader, Image } from '@aws-amplify/ui-react'

import { BiTransfer, BiTime } from 'react-icons/bi'

import * as models from '@mytmpvpn/mytmpvpn-common/models/vpn'
import { convertSecondsToHMS } from '../Common'


export function Metrics({ metrics }: { metrics: models.VpnMetrics | undefined }) {
  console.log(`Metrics: ${JSON.stringify(metrics)}`)
  return (
    <>{
      metrics ?
        <View fontSize={'small'}>
          <Flex alignItems={'center'}>
            <BiTransfer /><Text>{metrics.bytes === null ? 'N/A' : `${(metrics.bytes / 1024 / 1024).toFixed(2)} MiB`}</Text >
          </Flex>

          <Flex alignItems={'center'}>
            <BiTime /><Text>{metrics.duration === null ? 'N/A' : convertSecondsToHMS(metrics.duration)}</Text>
          </Flex>

          <Flex alignItems={'center'}>
            <Image alt='Number of peanuts' src='/favicon.ico' width={'1em'} /><Text>{metrics.peanuts === null ? 'N/A' : `${metrics.peanuts} peanuts`}</Text>
          </Flex>
        </View>

        :
        <Loader />
    }
    </>
  )
}
