import * as React from 'react'
import { MainTitle, WideView } from '../Common'
import { Link, Text, View } from '@aws-amplify/ui-react'

export default function Support() {
  return (
    <WideView>
      <MainTitle>Support</MainTitle>

      <Text>
        If you need support, please start with the <Link href="/faq">FAQ</Link>.<br/>
        If you don't find your answer, you can create a ticket on <Link href="https://gitlab.com/mytmpvpn/mytmpvpn-frontend/-/issues">gitlab</Link>.
        Alternatively, you can <Link href="/contactus">contact us</Link>.
      </Text>
    </WideView>
  )
}
