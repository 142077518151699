import React from 'react'
import { useAuthenticator, Heading, View, Link, Flex, Alert, Text } from '@aws-amplify/ui-react'
import { MainSubTitle, MainTitle, ShortCenteredTextView, WideView } from '../components/Common'
import { AccountSettings } from '@aws-amplify/ui-react';
import { MyTmpVpnClient } from '@mytmpvpn/mytmpvpn-client/client'


function UnregisterInternal({ client }: { client: MyTmpVpnClient }) {
  const handleSuccess = () => {
    // TODO: call client.deregister()
    alert("You've been deregistered successfully from MyTmpVpn. Your account has been deleted. Please contact us if you have any questions.")
  }

  return (
    <AccountSettings.DeleteUser onSuccess={handleSuccess} />
  )
}

export function Unregister({ client }: { client: MyTmpVpnClient }) {
  const { route } = useAuthenticator((context) => [context.route])

  return (
    <ShortCenteredTextView>
      <MainTitle>Unregister</MainTitle>
      <Alert variation="warning">
        This will delete your account! There is no way to rollback.
        Please double check this is what you want to do.
      </Alert>

      { route === 'authenticated' ? <UnregisterInternal client={client} /> : <Link href="/login">Please authenticate</Link> }
    </ShortCenteredTextView>
  )
}