import React from 'react'
import { Divider, Link, Text, Card, Message } from '@aws-amplify/ui-react'
import { ShortCenteredTextView } from '../Common'


export function NotFound() {
    return (
        <ShortCenteredTextView>
            <Card>
                <Message
                    variation='filled'
                    heading='Page not found'
                    colorTheme='error'
                    hasIcon={true}
                    textAlign={'left'}
                >This is an error on our end. We apologize for any inconvenience.
                </Message>

                <Divider marginBlock={'2em'} size='large' />

                <Text fontWeight={'bold'}>But you can contribute!</Text>

                We encourage you to <Link href="/issue">create an issue</Link> and describe how you landed here.<br />
                This will help us and the whole community.

                <Divider marginBlock={'2em'} size='large' />

                <Text>
                    Then, you can:<br />
                    <Link href="/">return to the homepage</Link><br />
                    <Link href="/faq">read the FAQ</Link><br />
                    <Link href="/contactus">contact us</Link><br />
                </Text>
            </Card>
        </ShortCenteredTextView >
    )
}