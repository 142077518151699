import React from 'react'
import { Auth } from '@aws-amplify/auth'
import { Divider, useAuthenticator } from '@aws-amplify/ui-react'
import { MyTmpVpnClient } from '@mytmpvpn/mytmpvpn-client/client'
import { Vpn, VpnMetrics } from '@mytmpvpn/mytmpvpn-common/models/vpn'
import { useErrorContext } from '../ErrorContext'
import { WideView } from '../components/Common'
import { Dashboard } from './Dashboard'
import { Welcome } from './Welcome'
import { Regions } from './Regions'

export interface VpnWithMetrics {
    vpn: Vpn
    metrics: VpnMetrics | undefined
}

export const VPNS_PAGE_SIZE = 4

export async function listVpnsPage(client: MyTmpVpnClient,
    setVpnsWithMetrics: any,
    setListingInProgress: any,
    pageTokens: any,
    setPageTokens: any,
    currentPageIndex: number,
    setHasMorePages: any,
    setError: any) {

    setListingInProgress(true)
    console.log(`pageTokens(${pageTokens.length}): ${JSON.stringify(pageTokens)}`)
    console.log(`currentPageIndex: ${currentPageIndex}`)
    console.log(`Fetching page ${currentPageIndex} with pageToken[${currentPageIndex - 1}]: ${pageTokens[currentPageIndex - 1]}`)
    return client.listVpnsPaginated({ pageSize: VPNS_PAGE_SIZE, nextPageToken: pageTokens[currentPageIndex - 1] })
        .then(listResponse => {
            const vpns = listResponse.vpns
            let n = vpns.length
            setHasMorePages(listResponse.nextPageToken !== undefined)
            console.log(`Response to paginated list given: ${JSON.stringify(listResponse)}`)
            setPageTokens((prevPageTokens: string[]) => {
                if (currentPageIndex === pageTokens.length && listResponse.nextPageToken) {
                    console.log(`Adding token ${listResponse.nextPageToken} to pageTokens`)
                    return [...prevPageTokens, listResponse.nextPageToken]
                } else {
                    return prevPageTokens
                }
            })
            const result: VpnWithMetrics[] = []
            if (vpns.length === 0) {
                setListingInProgress(false)
                return
            }
            vpns.forEach(vpn => {
                client.getVpn(vpn.vpnId)
                    .then(getResponse => {
                        result.push(getResponse)
                        console.log(`Fetched vpn: ${n} ${JSON.stringify(getResponse)}`)
                    }).catch(err => {
                        setError(err)
                    }).finally(() => {
                        if (--n <= 0) {
                            setVpnsWithMetrics(result)
                            setListingInProgress(false)
                        }
                    }
                    )
            })
        }).catch(err => {
            setError(err)
        })
}

export function Home({ client }: { client: MyTmpVpnClient }) {
    const { error, setError } = useErrorContext()
    const { user, authStatus } = useAuthenticator((context) => [context.user])
    const [vpnsWithMetrics, setVpnsWithMetrics] = React.useState<VpnWithMetrics[]>([])
    // TODO: do something about this status. Present the fact that listing is in progress
    const [listingInProgress, setListingInProgress] = React.useState(false)

    const [pageTokens, setPageTokens] = React.useState([''] as (string)[])
    const [currentPageIndex, setCurrentPageIndex] = React.useState(1)
    const [hasMorePages, setHasMorePages] = React.useState(true)
    const [balance, setBalance] = React.useState<number>(Number.NaN)

    React.useEffect(() => {
        Auth.currentSession()
            .then(session => {
                console.log(`Fetching session: ${JSON.stringify(session.getIdToken())}`)
                client.setUserSession(user, session)
            }).then(() => {
                console.log(`Fetching balance for user: ${JSON.stringify(user)}`)
                return client.getPeanutsBalance()
            }).then(peanuts => {
                setBalance(peanuts)
            }).then(() => {
                console.log(`List vpns for user: ${JSON.stringify(user)}`)
                listVpnsPage(client,
                    setVpnsWithMetrics,
                    setListingInProgress,
                    pageTokens,
                    setPageTokens,
                    currentPageIndex,
                    setHasMorePages,
                    setError)
            }).catch(err => setError)
    }, [])

    return (
        <WideView>
            {authStatus === 'authenticated' ?
                <Dashboard
                    client={client}
                    balance={balance}
                    setBalance={setBalance}
                    vpnsWithMetrics={vpnsWithMetrics}
                    setVpnsWithMetrics={setVpnsWithMetrics}
                    pageTokens={pageTokens}
                    setPageTokens={setPageTokens}
                    currentPageIndex={currentPageIndex}
                    setCurrentPageIndex={setCurrentPageIndex}
                    hasMorePages={hasMorePages}
                    setHasMorePages={setHasMorePages}
                />
                : <Welcome client={client} />}
            <Divider size='large' padding={10} />
            <Regions client={client} balance={balance} setVpnsWithMetrics={setVpnsWithMetrics} />
        </WideView >
    )
}