import { Heading, Link, Text, View } from '@aws-amplify/ui-react'
import * as React from 'react'
import { MainTitle, WideView, TitleSection } from '../Common'

export default function PrivacyPolicy() {
  return (
    <WideView>
      <MainTitle>Privacy Policy</MainTitle>

      This Privacy Policy ("Policy") describes how MyTmpVpn ("Company," "we," or "us") collects,
      uses, discloses, and protects the personal information of users ("you" or "users")
      when you access or use the MyTmpVpn service ("Service").
      This Policy applies only to your use of the Service. Policy applies to the collection and
      processing of personal information within the jurisdiction of British Columbia, Canada.


      <TitleSection>Information We Collect</TitleSection>

      We may collect the following types of personal information:
      <ul>
        <li>Account Information: When you sign up for the Service, we may collect your name,
          email address, username, and password.</li>
        <li>Usage Information: We may collect information about your usage of the Service,
          including connection logs, IP addresses, and the duration and frequency of your VPN sessions.</li>
        <li>Device Information: We may collect information about the device you use to access the Service,
          including device type, operating system, and browser information.</li>
        <li>Cookies and Tracking Technologies: We may use cookies and similar tracking technologies to
          enhance your experience on the Service and collect additional information about your usage.</li>

      </ul>

      <TitleSection>Use of Personal Information</TitleSection>
      We may use your personal information for the following purposes:
      <ul>
        <li>To provide and maintain the Service, including account management and customer support.</li>
        <li>To personalize and improve your experience on the Service.</li>
        <li>To analyze usage patterns and trends to enhance the Service's functionality and performance.</li>
        <li>To communicate with you about the Service, updates, and relevant promotional offers.</li>
        <li>To comply with legal obligations, enforce our rights, and prevent fraud or illegal activities.</li>
      </ul>

      <TitleSection>Disclosure of Personal Information</TitleSection>
      We may disclose your personal information in the following circumstances:
      <ul>
        <li>With your consent or as necessary to provide the Service.</li>
        <li>To trusted third-party service providers who assist us in delivering the Service.</li>
        <li>In response to a legal request, such as a court order or government investigation.</li>
        <li>To protect our rights, property, or safety, and that of our users or the public.</li>
        <li>In connection with a business transfer, such as a merger, acquisition, or sale of assets.</li>
      </ul>


      <TitleSection>Data Retention</TitleSection>
      We retain personal information for as long as necessary to fulfill the purposes outlined
      in this Policy, unless a longer retention period is required or permitted by law.

      <TitleSection>Data Security</TitleSection>
      We implement reasonable security measures to protect your personal information from
      unauthorized access, disclosure, or alteration. However, no method of transmission over the
      Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.

      <TitleSection>Your Rights</TitleSection>
      You have certain rights regarding your personal information, including the right to
      access, correct, update, or delete your information. You may also have the right to
      restrict or object to certain processing activities. To exercise these rights,
      please <Link href="/contactus">contact us</Link>.

      <TitleSection>Children's Privacy</TitleSection>
      The Service is not intended for use by individuals under the age of 19.
      We do not knowingly collect personal information from children. If you believe that we
      have inadvertently collected personal information from a child,
      please <Link href="/contactus">contact us immediately</Link>.

      <TitleSection>Changes to This Policy</TitleSection>
      We may update this Policy from time to time. Any material changes will be effective upon
      posting the revised Policy on the Service. We encourage you to periodically review this Policy
      for the latest information.

      <TitleSection>Contact Us</TitleSection>
      If you have any questions, concerns, or requests regarding this Policy or our privacy practices,
      please <Link href="/contactus">contact us</Link>.

      <TitleSection>Third-Party Links</TitleSection>

      The Service may contain links to third-party websites or services.
      This Policy does not apply to those third-party websites or services.
      We encourage you to review the privacy policies of those third parties
      before providing any personal information.


      By using the Service, you acknowledge that you have read, understood,
      and agreed to the terms of this Privacy Policy.
    </WideView>
  )
}
