import { Card, Collection, Flex, Text, Heading, Link, ScrollView, View } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';


interface Heading {
    id: string
    text: string | null
    level: number
}

function itemToC(item: Heading) {
    // Return the string given by item.text prefixed by as many '*' characters as item.level
    return <div style={{'fontSize': (4/item.level)+'em'}}>{item.text}</div>
}

export function TableOfContents({ headingsCommaSeparatedList }: { headingsCommaSeparatedList: string }) {
    const [headings, setHeadings] = useState<Heading[]>([]);

    useEffect(() => {
        // Get all the headings (h1, h2, h3, etc.) from the document
        const headingElements = document.querySelectorAll(headingsCommaSeparatedList);

        // Convert NodeList to an array and extract relevant information
        const headingsArray: Heading[] = Array.from(headingElements)
            .filter((heading) => heading.id && heading.textContent)
            // .sort((a, b) => a.textContent!.localeCompare(b.textContent!))
            .map((heading) => ({
                id: heading.id,
                text: heading.textContent,
                level: parseInt(heading.tagName.charAt(1), 10),
            }))
        setHeadings(headingsArray)
    }, [])

    return (
        <View>
            <Heading level={1}>Table of Contents</Heading>
            <Collection
                items={headings}
                type="list"
                direction={'column'}
                wrap={'nowrap'}
                gap={'0.2em'}>
                {(item, index) => (
                    <Link key={index} href={`#${item.id}`}>{itemToC(item)}</Link>

                )}
            </Collection>
        </View>
    )
}
