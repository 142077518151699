import React, { createContext, useState, useContext } from 'react'

interface ErrorContextProps {
  error: string | null
  setError: (errorMessage: string | null) => void
}

const ErrorContext = createContext<ErrorContextProps>({
  error: null,
  setError: () => { }
})

export const useErrorContext = () => useContext(ErrorContext)

export const ErrorProvider = ({ children }: { children: React.ReactNode }) => {
  const [error, setError] = useState<string | null>(null)

  return (
        <ErrorContext.Provider value={{ error, setError }}>
            {children}
        </ErrorContext.Provider>
  )
}
