import React from 'react'
import { Link, useAuthenticator, Text } from '@aws-amplify/ui-react'
import { TitleSection, ShortCenteredTextView, TitleSubSection } from '../Common'


export function Instructions() {
    const { authStatus } = useAuthenticator((context) => [context.authStatus])

    return (
        <ShortCenteredTextView>
            <TitleSubSection>Vpn Creation Steps</TitleSubSection>
                <ol style={{marginTop: '-1em'}} start={0}>
                    <li>Check you've <Link href="https://wireguard.com">WireGuard</Link> installed, 
                    and enough <Link href='/pricing'>peanuts</Link>;</li>
                    <li>Create a vpn in the region you want below;</li>
                    <li>Download your vpn configuration, and connect to it;</li>
                    <li>Delete your vpn when done.</li>
                </ol>
            
            <Text marginTop={'-1.5em'} marginBottom={'1.25em'} fontSize={'smaller'}>
                See the <Link href='/faq#connection'>FAQ</Link> for more detailed instructions.
            </Text>
        </ShortCenteredTextView >
    )
}