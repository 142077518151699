import React from 'react'
import { Badge, Button, Divider, Flex, Heading, Link, Image, Text, View } from '@aws-amplify/ui-react'
import { useNavigate } from 'react-router-dom'
import { MainTitle, ShortCenteredTextView, WideView } from '../Common'


export function Issue() {
    return (
        <ShortCenteredTextView>
            <MainTitle>Issue</MainTitle>

            <Text>
                We use <Link isExternal={true} href="https://gitlab.com/mytmpvpn/mytmpvpn-frontend/-/issues">gitlab</Link> for issue management.<br/>
                Before creating such an issue, read the <Link href="/faq">FAQ</Link>.
            </Text>
        </ShortCenteredTextView>
    )
}