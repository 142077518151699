import React from 'react';

import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react'
import { Auth } from '@aws-amplify/auth'

import '@aws-amplify/ui-react/styles.css'

import { useNavigate, useLocation } from 'react-router'
import { MyTmpVpnClient } from '@mytmpvpn/mytmpvpn-client/client'

export function Login({ client, initialState }: { client: MyTmpVpnClient, initialState: "signIn" | "signUp" }) {
  const { route } = useAuthenticator((context) => [context.route])
  const location = useLocation()
  const navigate = useNavigate()
  let from = location.state?.from?.pathname || '/'

  React.useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true })
    }
  }, [route, navigate, from])
  return (
    <View className="auth-wrapper">
      <Authenticator
        signUpAttributes={['email']} 
        initialState={initialState} 
        />
    </View>
  )
}