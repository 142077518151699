import React from 'react'
import { TitleSection, ShortCenteredTextView } from '../components/Common'
import { Instructions } from '../components/static/Instructions'
import { Button, Link, Text, View } from '@aws-amplify/ui-react'
import { MyTmpVpnClient } from '@mytmpvpn/mytmpvpn-client/client'
import Pricing from './Pricing'
import { useNavigate } from 'react-router-dom'


export function Welcome({ client }: { client: MyTmpVpnClient }) {
    return (
        <View>
            <ShortCenteredTextView>
                <TitleSection>Subscription-free Vpn Service</TitleSection>
                MyTmpVpn is a prepaid Virtual Private Network service.<br/>
                Each vpn provisioned is:
                    <ul>
                        <li><b>Secure:</b> runs <Link href="https://wireguard.com">WireGuard</Link></li>
                        <li><b>Isolated:</b> runs on its own <Link href="https://aws.com">AWS</Link> EC2 server</li>
                        <li><b>Temporary:</b> will be destroyed eventually</li>
                    </ul>
                <Text>Need more info? Read the <Link href='/faq'>FAQ</Link> or <Link href='/contactus'>contact us</Link>.</Text>
                <Text fontSize={'large'} fontWeight={'bold'}>Convinced? Give it a try, <Link href='/signup'>sign up!</Link></Text>
            </ShortCenteredTextView >
            <Pricing client={client} />
        </View>
    )
}