import { Card, Link, View } from '@aws-amplify/ui-react'
import * as React from 'react'
import { Code, Emph, MainSubTitle, MainTitle, ShortCenteredTextView, TitleSubSection, WideView, titleToId } from '../Common'
import { TableOfContents } from './TableOfContents'


export default function Faq() {
  return (
    <WideView>
      <Card textAlign={'center'}>
        <TableOfContents headingsCommaSeparatedList={'h2,h4'} />
      </Card>
      <MainTitle>Frequently Asked Questions</MainTitle>
      <MainSubTitle>About MyTmpVpn</MainSubTitle>

      <TitleSubSection>What makes MyTmpVpn unique?</TitleSubSection>
      <Emph>Pre-paid, No Subscription:</Emph> MyTmpVpn operates on a pre-paid model. Instead of monthly subscriptions, customers can purchase <Link href="/faq#whatarepeanuts">peanuts</Link> for a small fee. A running VPN consumes peanuts until it is manually or automatically deleted for example when there are no more peanuts in the account or after some time limit you set in advance.<br/>

      <Emph>Open Source:</Emph> The service is built on open source tools like WireGuard and will be open-sourced itself both front-end and backend by 2024. This allows transparency, trust, and community contribution to the code base.<br/>

      <Emph>Community Based:</Emph> MyTmpVpn can be run by trusted individuals rather than a centralized company. This creates a community network where you can choose someone you know to operate your VPN rather than relying on a faceless corporation.<br/>

      <Emph>Temporary Nature</Emph>: VPNs automatically delete with full log wiping for privacy.<br/>

      <TitleSubSection>How does the pricing work?</TitleSubSection>
      <Emph>Peanut-Based Pricing:</Emph> Customers buy peanuts, and each running VPN consumes peanuts at a rate that depends on resources consumption including  CPU or network traffic among others. This innovative pricing model allows for cost control and flexibility.<br/>

      
      <TitleSubSection>Why is it called "Temporary VPN"?</TitleSubSection>
      <Emph>Temporary Nature:</Emph> MyTmpVpn follows a temporary VPN model. VPNs are set to be eventually destroyed, and all logs within them are wiped clean upon deletion. This approach prioritizes user privacy and data security.<br/>

      <TitleSubSection>What are the advantages of the temporary VPN model?</TitleSubSection>
      <Emph>Enhanced Privacy:</Emph> The temporary nature of MyTmpVpn ensures that all logs and data within the VPN are deleted when it's destroyed, contributing to enhanced user privacy and security.<br/>

      <Emph>Flexibility:</Emph> VPNs can be created on demand for short term needs.<br/>

      <TitleSubSection>Can I trust the infrastructure of MyTmpVpn?</TitleSubSection>
      <Emph>Amazon EC2 Instances:</Emph> MyTmpVpn leverages dedicated virtual machines on Amazon EC2 instances, a trusted and reliable infrastructure provider. This ensures the stability and performance of the VPN service.<br/>

      <Emph>Amazon EC2 VMs:</Emph> Leverages reliable AWS infrastructure.<br/>

      <Emph>Open Source Software:</Emph> The underlying VPN software is open source (WireGuard) allowing transparency.<br/>

      <Emph>Community-Based:</Emph> Rather than a corporation, the service is run by a trusted community of individuals. You can choose someone you know.<br/>

      <TitleSubSection>What happens if my VPN runs out of peanuts?</TitleSubSection>
      If a VPN runs out of peanuts, it is deleted automatically.<br/>

      <TitleSubSection>What does MyTmpVpn mean?</TitleSubSection>
      MyTmpVpn stands for "My Temporary Virtual Private Network". This name makes it clear:

      <ul>
        <li>A virtual private network (VPN) server is provisioned by MyTmpVpn exclusively for you;</li>
        <li>The VPN server is temporary, meant to be used for a limited time.</li>
      </ul>

      <TitleSubSection>What are peanuts?</TitleSubSection>
      Peanuts represent the cloud computing resources consumed by a VPN session, including server processing time and network traffic.<br/>

      <TitleSubSection>What is the VPN software used?</TitleSubSection>
      We leverage <Link isExternal={true} href="https://wireguard.org">WireGuard</Link>, an open-source VPN software.<br/>

      <TitleSubSection>What infrastructure is used?</TitleSubSection>
      Each VPN actually runs one instance of <Link isExternal={true} href="https://www.wireguard.com/">WireGuard</Link> on a dedicated Amazon EC2 instance.<br/>

      <MainSubTitle>Connection</MainSubTitle>

      <TitleSubSection>What do I need before connecting?</TitleSubSection>
      Before creating your own VPN, you need to:

      <ol>
        <li>Install <Link isExternal={true} href="https://wireguard.com">WireGuard</Link> on the device you want to connect from (PC, smartphone, ...);</li>
        <li><Link href="/login">Create a MyTmpVpn account</Link></li>
        <li><Link href="/pricing">Add peanuts to your account balance</Link></li>
      </ol>

      <TitleSubSection>How do I connect from a computer?</TitleSubSection>

      <ol start={0}>
        <li>Complete the <Link href={`#${titleToId("What do I need before connecting?")}`}>prerequisites</Link>;</li>
        <li>Create a VPN in a region and wait for the status to show Running;</li>
        <li>Click the Config button to download the <Link isExternal={true}>WireGuard</Link> configuration file;</li>
        <li>Use that file to configure your WireGuard client. On linux, this is done using the following instruction:
          <Code>sudo wg-quick up you_downloaded_file.conf</Code>
        </li>
      </ol>

      <TitleSubSection>How to use the QR Code?</TitleSubSection>
      The QR Code is a convenient way to connect from your smartphone.But you need to scan the QR Code <b> from the WireGuard application</b> not from your smartphone camera. To do this, open the WireGuard application, click on '+', then click on 'Scan from QR code' and scan the QR Code that is displayed for your vpn.


      <TitleSubSection>How do I check I am connected ?</TitleSubSection>
      To check if you are connected to your VPN, you can use any external web sites; <Link isExternal={true} href="https://duckduckgo.com/?q=what+is+my+ip+address&t=h_&ia=answer">here is a list</Link>. From there, you can verify your IPv4 has changed and is geo-localized in the selected region.<br/>

      If you need to verify in a script, you might try <Link isExternal={true} href="https://ifconfig.me">ifconfig.me</Link> as in:

      <Code>curl ifconfig.me</Code>

      and verify your IPv4 has changed before and after the connection.<br/>


      <MainSubTitle>Security</MainSubTitle>

      <TitleSubSection>Isn't the use of AWS an issue on privacy?</TitleSubSection>

      Some may see a contradiction in using Amazon/AWS cloud services for a privacy-focused VPN, since Amazon is a large tech company. However, AWS provides operational excellence and strong security controls that can support building a secure, private VPN service. Many consumer VPN providers actually rely on cloud platforms like AWS rather than owning their entire network, so using AWS does not preclude building a private, user-controlled VPN service.<br/>
      Each VPN in MyTmpVpn is assigned a dedicated virtual machine with no logging or remote access enabled.

      <TitleSubSection>Is there a risk of connection sharing between VPNs?</TitleSubSection>
      <Emph>Dedicated Virtual Machines:</Emph> Each VPN server runs on its dedicated virtual machine, specifically an <Link href="https://aws.amazon.com/ec2/">Amazon EC2 instance</Link>. This adds another layer of security beyond the VPN encryption itself as it ensures that there is no connection sharing between two different VPNs, providing a secure and isolated environment for each user.<br/>

      <TitleSubSection>My IPv6 is still not hidden, what's going on?</TitleSubSection>

      As of 2023-11, MyTmpVpn does not support IPv6.
      Thumbs-up this <Link isExternal={true} href="https://gitlab.com/mytmpvpn/mytmpvpn-frontend/-/issues/3"> gitlab issue</Link> to get it prioritized.<br/>

      Mitigation is to disable IPv6 entirely as explained in section <Link href={`#${titleToId("How to disable IPv6?")}`}> below</Link>.<br/>

      <TitleSubSection>How to disable IPv6?</TitleSubSection>

      Search for this on <Link href="https://www.google.com/search?q=how+to+disable+ipv6"> Google</Link>. For Linux you can use:
      <Code>
        sudo sysctl -w net.ipv6.conf.all.disable_ipv6=1<br />
        sudo sysctl -w net.ipv6.conf.default.disable_ipv6=1
      </Code>

      <TitleSubSection>What is the most secured configuration?</TitleSubSection>

      On your Android cell phone, in Settings/Connections/More_connection_settings/VPN/WireGuard/ we suggest you turn on:
      <ul>
        <li>Always-on VPN</li>
        <li>Block connections without VPN</li>
      </ul> 

      On Linux, we suggest you turn off IPv6 as it's not supported by MyTmpVpn as of 2023-12. See section <Link href="#howtodisableipv6">above</Link> for instructions.<br/>

      <TitleSubSection>Why is my IP seen from Amazon?</TitleSubSection>
      As of 2023-11 we provision an actual <Link isExternal={true} href="https://aws.amazon.com/ec2/"> Amazon EC2 instance</Link> to run your VPN server.
      As of 2023, according to Statista, Amazon is the biggest cloud provider worldwide [<Link isExternal={true} href="https://www.statista.com/chart/18819/worldwide-market-share-of-leading-cloud-infrastructure-service-providers/">ref</Link>].

      <MainSubTitle>Regions</MainSubTitle>
      <TitleSubSection>Can you support region X?</TitleSubSection>
      Supporting a new region incurs both capital and operational expenses. 
      We will open a new region according to various criteria including infrastructure availability and customer demand. 
      We encourage you to <Link href="/ticket">create a ticket</Link> with your request for that region and to ask your friends to upvote for it.<br/>

      <MainSubTitle>Troubleshoot</MainSubTitle>

      <TitleSubSection>Why can't I use the internet anymore suddenly while connected to my VPN?</TitleSubSection>
      Your VPN might have been terminated. Reasons include:
      <ul>
        <li>You don't have any more peanuts in your account;</li>
        <li>All peanuts given to your VPN at creation time have been consumed;</li>
        <li>Your VPN has reached the deadline given at creation time;</li>
      </ul>

      <TitleSubSection>Can I resurect a deleted VPN so I can get my connection back?</TitleSubSection>
      No. You can't resurect a deleted VPN. But you can create a new one easily as long as you have enough peanuts.<br/>
      
      <TitleSubSection>How to connect back to the internet after I have deleted my VPN?</TitleSubSection>
      You should re-configure your device to connect to the internet without the VPN.
      Instructions depend on your device (Android, Linux, iOs, Mac, Windows,...). 
      
      On Linux, you can use the following command:

      <Code>sudo wg-quick down you_downloaded_file.conf</Code>

      On Android you can go to Settings/Connections/More_connection_settings/VPN/WireGuard/ and deactivate Always-on VPN.<br/>
     

      <MainSubTitle>Software Development</MainSubTitle>
      <TitleSubSection>Is MyTmpVpn open-source?</TitleSubSection>
      Not entirely yet. We are targetting an open-source release by 2024. Stay tuned!

      <TitleSubSection>Is there a Command Line Interface (CLI)?</TitleSubSection>
      Yes. <Code> npx @mytmpvpn/mytmpvpn-cli</Code> is the CLI. Documentation is available <Link href="/doc">here</Link>. <br/>

      <TitleSubSection>Is There An SDK ?</TitleSubSection>
      Yes. See <Link isExternal={true} href="https://gitlab.com/mytmpvpn/mytmpvpn-client"> MyTmpVpn project on GitLab</Link>. <br/>
    </WideView>
  )
}
