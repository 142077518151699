import * as React from 'react'

import { Divider, Link, Message, Text, View } from '@aws-amplify/ui-react'
import { MainTitle, TitleSection, WideView } from '../Common'

export default function Contribute() {
  return (
    <WideView>
      <MainTitle>Contribute</MainTitle>

      <Message
        variation='filled'
        colorTheme='info'
        hasIcon={true}
        heading='Contribute Now!'
      >
        <View>Immediate and easy way to contribute is to provide feedback: create an <Link href="https://gitlab.com/mytmpvpn/mytmpvpn-frontend/-/issues/new">issue now!</Link></View>
      </Message>

      <Divider marginBlock={'2rem'} size='large' />

      You are also welcome to help in the development of the MyTmpVpn project.<br />
      We are working on releasing the whole project frontend, library and backend as an open-source project by 2024.<br />
      For the time being, here are several areas below where talents are needed and welcome.

      <TitleSection>Web FrontEnd</TitleSection>

      The source code of the webfrontend is available on <Link isExternal={true} href="https://gitlab.com/mytmpvpn/mytmpvpn-frontend/">gitlab</Link>.
      From there, here are the immediate needs:

      <ul>
        <li>Logo design: we need a better logo, <Link href="/contactus">contact us</Link> for more information;</li>
        <li>Web design: React and Amplify-UI are used for the frontend in their default settings; no style, no css. This could be tuned.
          You can start from the <Link isExternal={true} href="https://gitlab.com/mytmpvpn/mytmpvpn-frontend/">gitlab source</Link> fork,
          create a branch and send a Pull request with your change;</li>
        <li>Automated front-end testing</li>
        <li>Translation/localisation: automated or not</li>
      </ul>

      <TitleSection>Command Line Interface</TitleSection>

      The source code of the CLI is available on <Link isExternal={true} href="https://gitlab.com/mytmpvpn/mytmpvpn-cli/">gitlab</Link>.
      Feel free to experiment right-away and send us your pull-request.

      <TitleSection>Library</TitleSection>

      The source code of the library is available on <Link isExternal={true} href="https://gitlab.com/mytmpvpn/mytmpvpn-client/">gitlab</Link>.
      Though we have a full end-to-end integration tests, we don't have proper unit-tests as it requires mocking all over the place.
      This is the main area that needs improvement as of 2023-11. Other area includes:

      <ul>
        <li>Caching: many backend results could be cached;</li>
        <li>Transforming into an OpenAPI api instead of this ad-hoc version;</li>
      </ul>


      <TitleSection>Backend</TitleSection>

      We are working on releasing the backend as an open-source product by 2024. Stay tuned!
      If you have some ideas though, you're also welcome to <Link href="/contactus">contact us</Link>.


      There are also several other tasks, large and small on both the frontend and backend side with the following technologies:
      <ul>
        <li>React</li>
        <li>TypeScript</li>
        <li>AWS Services: Lambda, StepFunctions, Cognito, DynamoDb, S3, Qldb, ...</li>
        <li>AWS Cloud Development Kit (CDK)</li>
      </ul>

      If you want to contribute to this project, <Link href="/contactus">contact us</Link>.

    </WideView>
  )
}
