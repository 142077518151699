import * as React from 'react'
import { MainTitle, WideView, TitleSection } from '../Common'
import { Link, View } from '@aws-amplify/ui-react'

export default function TermsOfUse() {
  return (
    <WideView>
      <MainTitle>Terms Of Service</MainTitle>

      These Terms of Service ("Agreement") govern your use of the MyTmpVpn service ("Service")
      provided by MyTmpVpn ("Company"). By accessing or using the Service, you agree to be bound
      by this Agreement. If you do not agree with any part of this Agreement,
      you may not use the Service.

      <TitleSection>Description of Service</TitleSection>

      The Service provides temporary VPN (Virtual Private Network) connections to users,
      allowing them to browse the internet securely and privately.

      <TitleSection>Eligibility</TitleSection>

      You must be at least 18 years old or the legal age of majority in your jurisdiction to use the Service.
      By using the Service, you represent and warrant that you meet the eligibility requirements.

      <TitleSection>User Obligations</TitleSection>
      You agree to use the Service in compliance with all applicable laws and regulations.
      You are responsible for maintaining the confidentiality of your account information,
      including your username and password.
      You agree not to share your account credentials or engage in any activities that may compromise
      the security of your account or the Service.

      <TitleSection>Prohibited Activities</TitleSection>
      You shall not use the Service for any illegal, unauthorized, or prohibited purposes,
      including but not limited to:
      <ol>
        <li>Violating any laws or regulations;</li>
        <li>Distributing malware or engaging in hacking or unauthorized access to systems;</li>
        <li>Uploading or sharing any content that infringes intellectual property rights or violates
          privacy or confidentiality;</li>
        <li>Interfering with the functioning of the Service or attempting to gain unauthorized access
          to its infrastructure;</li>
        <li>Engaging in any activities that may disrupt or harm the Service or its users.</li>
      </ol>

      <TitleSection>Privacy and Data Protection</TitleSection>
      We are committed to protecting your privacy and handling your personal information in accordance
      with applicable privacy laws, including the <Link href="https://www.bclaws.gov.bc.ca/civix/document/id/complete/statreg/03063_01" target="_blank">Personal Information Protection Act (PIPA)</Link> of British Columbia. By using the Service, you consent to the collection, use, and disclosure
      of your personal information as described in the <Link href="/privacy">Privacy Policy</Link>.

      <TitleSection>Intellectual Property</TitleSection>
      The Service and all associated intellectual property rights are owned by the Company.
      You acknowledge that you have no rights or ownership over the Service, its logo, trademarks,
      or any other materials, except as expressly granted in this Agreement.
      You agree not to use, copy, modify, or distribute any part of the Service without prior written
      consent from the Company.

      <TitleSection>Fees and Payment</TitleSection>
      Certain features or usage of the Service may require payment.
      You agree to pay all fees associated with your use of the Service as specified in the
      applicable payment terms.
      All fees are non-refundable unless expressly stated otherwise in the Payment and Refund Policy.

      <TitleSection>Disclaimer of Warranties</TitleSection>
      The Service is provided on an "as is" and "as available" basis without warranties of any kind.
      The Company makes no representations or warranties regarding the Service's reliability,
      availability, security, or suitability for your specific needs.
      You use the Service at your own risk.

      <TitleSection>Limitation of Liability</TitleSection>
      To the maximum extent permitted by law, the Company and its affiliates, directors, officers,
      employees, or agents shall not be liable for any indirect, consequential, incidental,
      punitive, or special damages arising out of or in connection with the Service,
      including but not limited to loss of profits, data, or business opportunities.

      <TitleSection>Termination</TitleSection>
      The Company may suspend or terminate your access to the Service at any time without notice
      for any reason, including violation of this Agreement. Upon termination, you shall cease all
      use of the Service, and any provisions of this Agreement that should reasonably survive
      termination will continue to apply.

      <TitleSection>Modifications to the Agreement</TitleSection>
      The Company reserves the right to modify or update this Agreement at any time.
      Any material changes will be communicated to you through the Service or by other means.
      Your continued use of the Service after such modifications constitutes your acceptance
      of the updated Agreement.

      <TitleSection>Governing Law and Jurisdiction</TitleSection>
      This Agreement shall be governed by and construed in accordance with the laws
      of British Columbia, Canada.
      Any disputes arising out of or in connection with this Agreement shall be subject to the
      exclusive jurisdiction of the courts of British Columbia.

      <TitleSection>Entire Agreement</TitleSection>
      This Agreement constitutes the entire agreement between you and the Company
      regarding the Service and supersedes any prior or contemporaneous agreements,
      understandings, or representations, whether oral or written.

      If any provision of this Agreement is found to be invalid or unenforceable,
      the remaining provisions shall continue to be valid and enforceable to the fullest
      extent permitted by law.

      By using the Service, you acknowledge that you have read, understood,
      and agreed to be bound by this Agreement.

    </WideView>
  )
}
