import React from 'react'

import { Text, View, Flex, Table, TableHead, TableRow, TableCell, TableBody, Loader, Pagination } from '@aws-amplify/ui-react'


import { MyTmpVpnClient } from '@mytmpvpn/mytmpvpn-client/client'
import * as models from '@mytmpvpn/mytmpvpn-common/models/vpn'

import { MainTitle, convertSecondsToHMS } from '../Common'
import { VpnWithMetrics, listVpnsPage } from '../../pages/Home'
import { Actions } from './Actions'
import { Metrics } from './Metrics'
import { formatDuration, Duration } from 'date-fns'

const TERMINAL_VPN_STATE = [models.VpnState.Running, models.VpnState.Failed, models.VpnState.Deleted, models.VpnState.Paused]

function VpnMetrics({ vpnWithMetrics }: { vpnWithMetrics: VpnWithMetrics }) {
  return <View>
    {vpnWithMetrics.metrics &&
      <Flex alignItems={'center'}>
        <Metrics metrics={vpnWithMetrics.metrics} />
      </Flex>
    }
  </View>
}

function VpnConfig({ vpnWithMetrics }: { vpnWithMetrics: VpnWithMetrics }) {
  return <View fontSize={'small'}>
    <Text>
      maxPeanuts: {vpnWithMetrics.vpn.config.maxPeanuts}<br />
      deleteAfter: {vpnWithMetrics.vpn.config.deleteAfter ?
        convertSecondsToHMS(vpnWithMetrics.vpn.config.deleteAfter) :
        'N/A'}
    </Text>
  </View>
}

function VpnState({ vpnWithMetrics }: { vpnWithMetrics: VpnWithMetrics }) {
  return <View>
    {
      !TERMINAL_VPN_STATE.includes(vpnWithMetrics.vpn.state) &&
      <Flex direction={'row'}>
        <Loader marginRight={"0.5em"} />
        <Text fontSize={'smaller'}>~3mn</Text>
      </Flex>
    }
    <Text>{vpnWithMetrics.vpn.state}</Text>
  </View>
}

function VpnRegion({ vpnWithMetrics }: { vpnWithMetrics: VpnWithMetrics }) {
  return <View>
    <Text>
      {vpnWithMetrics.vpn.region}
    </Text>
  </View>
}

function VpnCreationDate({ vpnWithMetrics }: { vpnWithMetrics: VpnWithMetrics }) {
  return <View>
    <Text>
      {new Date(vpnWithMetrics.vpn.createdAt).toLocaleString()}
    </Text>
  </View>
}

export function Vpns({ client,
  vpnsWithMetrics,
  setVpnsWithMetrics,
  pageTokens,
  setPageTokens,
  currentPageIndex,
  setCurrentPageIndex,
  hasMorePages,
  setHasMorePages,
  setError }: {
    client: MyTmpVpnClient,
    vpnsWithMetrics: VpnWithMetrics[],
    setVpnsWithMetrics: any,
    pageTokens: string[],
    setPageTokens: any,
    currentPageIndex: number,
    setCurrentPageIndex: any,
    hasMorePages: boolean,
    setHasMorePages: any,
    setError: any,
  }) {
  // console.log(`Vpns list: ${JSON.stringify(vpnsWithMetrics.map(v => v.vpn))}`)

  const [listingInProgress, setListingInProgress] = React.useState<boolean>(false)

  const handleNextPage = () => {
    if (hasMorePages) {
      console.log(`Has More Pages; fetching next one: ${JSON.stringify(currentPageIndex)}`)
      listVpnsPage(client,
        setVpnsWithMetrics,
        setListingInProgress,
        pageTokens,
        setPageTokens,
        currentPageIndex + 1,
        setHasMorePages,
        setError
      ).then(() => {
        setCurrentPageIndex(currentPageIndex + 1)
      })
    }
  }

  const handlePrevPage = () => {
    listVpnsPage(client,
      setVpnsWithMetrics,
      setListingInProgress,
      pageTokens,
      setPageTokens,
      currentPageIndex - 1,
      setHasMorePages,
      setError
    ).then(() => {
      setCurrentPageIndex(currentPageIndex - 1)
    })
  }

  const handleOnChange = (pageIndex: number | undefined) => {
    listVpnsPage(client,
      setVpnsWithMetrics,
      setListingInProgress,
      pageTokens,
      setPageTokens,
      pageIndex!,
      setHasMorePages,
      setError
    ).then(() => {
      setCurrentPageIndex(pageIndex!)
    })
  }

  console.log(`currentPageIndex: ${currentPageIndex}; hasMorePages: ${hasMorePages}; pageTokens(${pageTokens.length}): ${JSON.stringify(pageTokens)}`)
  return (
    <View>
      <MainTitle>Vpns</MainTitle>
      <Pagination
        currentPage={currentPageIndex}
        totalPages={pageTokens.length}
        hasMorePages={hasMorePages}
        onNext={handleNextPage}
        onPrevious={handlePrevPage}
        onChange={handleOnChange}
      />
      <Table
        caption=""
        highlightOnHover={true}
        variation="striped">
        <TableHead>
          <TableRow>
            <TableCell as="th">Created At</TableCell>
            <TableCell as="th">Region</TableCell>
            <TableCell as="th">Config</TableCell>
            <TableCell as="th">State</TableCell>
            <TableCell as="th">Usage</TableCell>
            <TableCell as="th">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            vpnsWithMetrics &&
            vpnsWithMetrics.sort((a, b) => (a.vpn.createdAt < b.vpn.createdAt ? 1 : -1))
              .map((vpnWithMetrics, index) => (
                <TableRow key={index}>
                  <TableCell><VpnCreationDate vpnWithMetrics={vpnWithMetrics} /></TableCell>
                  <TableCell><VpnRegion vpnWithMetrics={vpnWithMetrics} /></TableCell>
                  <TableCell><VpnConfig vpnWithMetrics={vpnWithMetrics} /></TableCell>
                  <TableCell><VpnState vpnWithMetrics={vpnWithMetrics} /></TableCell>
                  <TableCell><VpnMetrics vpnWithMetrics={vpnWithMetrics} /></TableCell>
                  <TableCell>
                    {vpnWithMetrics.vpn.state === models.VpnState.Running &&
                      <Actions
                        client={client}
                        vpnWithMetrics={vpnWithMetrics}
                        vpnsWithMetrics={vpnsWithMetrics}
                        setVpnsWithMetrics={setVpnsWithMetrics} />
                    }
                  </TableCell>
                </TableRow>
              ))
          }
        </TableBody>
      </Table >
    </View>
  )
}
