import { Heading, Link, Text, View } from '@aws-amplify/ui-react'
import * as React from 'react'
import { MainTitle, WideView } from '../Common'

export default function History() {
  return (
    <WideView>
      <MainTitle>History</MainTitle>

      This project started in 2019-09, when we were considering using vpns temporarily.
      No such offer existed at that time, so we decided to build our own and came up with
      a first simple AWS CloudFormation stack that installs an OpenVpn on an AWS EC2 instance
      in a given region. This was released as an open-source product in 2020-08 
      on [<Link href="https://gitlab.com/pierre.vigneras/mytmpvpn">gitlab</Link>].

      Much later on, in 2022-09 we then realized this could interest other folks.
      
      <ul>
        <li>We started working on the backend as a hobby in 2022-09;</li>
        <li>by 2022-12, a CLI was ready to be used, but without CI/CD;</li>
        <li>by 2023-05, a CI/CD pipeline was built;</li>
        <li>by 2023-05, a front-end skeleton was ready to get online;</li>
        <li>by 2023-06, DNS name was purchased and the beta version published.</li>
      </ul>

    </WideView >
  )
}
