import React from 'react'
import { Badge, Button, Divider, Flex, Heading, Link, Image, Text, View } from '@aws-amplify/ui-react'
import { useNavigate } from 'react-router-dom'


export function Logo() {
    const navigate = useNavigate()
    return (
        <View>
            <Button variation='menu' onClick={() => navigate('/')}>
                <Image
                    alt="MyTmpVpn logo"
                    src="/mytmpvpn-website-favicon-black.png"
                    height="2em"
                    width="2em"
                    // opacity="100%"
                />MyTmpVpn<Badge variation='warning' size='small' marginLeft={'0.3em'}>Beta</Badge>
            </Button>
        </View>
    )
}