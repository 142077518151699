import { View, Text, Flex, Button } from '@aws-amplify/ui-react'
import React from 'react'

export default function ErrorBanner({ error, setError }: { error: any, setError: any }) {
    console.trace("An error occured", JSON.stringify(error))
    let errorMsg
    try {
        if ('response' in error && 'data' in error.response && error.response.data) {
            errorMsg = error.response.data
        } else if ('message' in error && error.message) {
            errorMsg = error.message
        } else {
            errorMsg = JSON.stringify(error)
        }
    } catch (e) {
        errorMsg = `Internal Error: ${JSON.stringify(error)}`
    }
    return (
        <View backgroundColor='red.10' className="error-banner">
            <Flex justifyContent='center' alignItems='center' direction='row'>
                <Text>{errorMsg}</Text> <Button onClick={() => setError()}>Dismiss</Button>
            </Flex>
        </View>
    )
}
