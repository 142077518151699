import { Link, Message, View } from '@aws-amplify/ui-react'
import * as React from 'react'
import { Code, MainTitle, WideView, TitleSection, TitleSubSection } from '../Common'
import { TableOfContents } from './TableOfContents'


export default function DocCli() {
  return (
    <WideView>
      <center>
        <TableOfContents headingsCommaSeparatedList='h3' />
      </center>

      <MainTitle>MyTmpVpn CLI Documentation</MainTitle>

      <Message colorTheme='info'>
        <View>
          This is the documentation for the <Link href='/'>MyTmpVpn</Link> Command Line Interface (mytmpvpn-cli).
          If you are looking for more general information on <Link href='/'>MyTmpVpn</Link>,
          see the <Link href="/doc">general documentation</Link> or the <Link href='/faq'>FAQ</Link>.
        </View>
      </Message>

      <TitleSection>Step 0: Pre-requisite</TitleSection>

      <TitleSubSection>Install Wireguard</TitleSubSection>
      Follow the pre-requisite <Link href="http://localhost:3000/faq#whatdoineedbeforeconnecting">instructions</Link>.

      Note that you must install <Link isExternal={true} href="https://www.wireguard.com">Wireguard</Link> on each device you want to connect from.
      It can be your phone and/or your laptop, host, ...<br />
      You then must install the CLI:
      <Code>npx @mytmpvpn/mytmpvpn-cli</Code>
      is all you need. For convenience we suggest you make a bash alias for it:

      <Code>alias mytmpvpn="npx mytmpvpn/mytmpvpn-cli"</Code>

      So you can use <code>mytmpvpn</code> instead of <code>npx @mytmpvpn/mytmpvpn-cli</code>.

      This is what we will use in the following.

      <TitleSection>Step 1: Register</TitleSection>

      <em>Unless you've not registered already using the web application, you will need to register first.
        If you've already registered, you can skip this step.</em><br />

      You need to register first:

      <Code>mytmpvpn register "your@email.com" "StrongPassword"  # Min: 8 characters with digit, lower, upper and symbols</Code>

      You will receive a confirmation code by email.

      <TitleSection>Step 2: Confirm registration</TitleSection>

      You will need to confirm your registration:

      <Code>mytmpvpn confirm-registration "code"  # The code you have received by email</Code>

      <TitleSection>Step 3: Buy some peanuts</TitleSection>

      Vpn consumes peanuts. <b>Using the email you used for registration</b>, purchase some peanuts <Link href="pricing">here</Link>.
      <br />
      Note: you can also get the list of peanuts packs from the CLI itself:

      <Code>mytmpvpn list-peanuts-packs</Code>

      <TitleSection>Step 4: Create/Delete Vpns</TitleSection>

      From there, use the following commands:

      <TitleSubSection>List available regions</TitleSubSection>
      <Code>mytmpvpn list-regions</Code>

      <TitleSubSection>Create a new vpn in Paris:</TitleSubSection>

      <Code>mytmpvpn create "paris" --sync</Code>
      Note: this takes several minutes, please be patient or remove <code>--sync</code> option.

      <TitleSubSection>List all my vpns</TitleSubSection>
      <Code>mytmpvpn list</Code>

      <TitleSubSection>Delete a given vpn</TitleSubSection>
      With the vpn-id returned by the list command, you can fetch the configuration:
      <Code>mytmpvpn delete "vpn-id"</Code>

      <TitleSection>Step 5: Connect to the vpn</TitleSection>
      To connect to the vpn, you need to fetch the configuration:
      <Code>mytmpvpn download-config "vpn-id"</Code>

      This will download the configuration file and print where it has been downloaded to.
      This file is a tar.gz that contains your vpn wireguard configuration.

      After dezipping that file, using something like:

      <Code>tar -xvf "CONFIG_FILE_RETURNED.tar.gz"</Code>

      you can either scan the provided qr-code (e.g: from your phone) using the wireguard application
      or you can use the wireguard script like in the following:

      <Code>sudo wg-quick up "CONFIG_REPO/mytmpvpn-wg.conf"</Code>

      From there, you can double check you are actually connected using an external site such as:

      <ul>
        <li><a href="https://www.myip.com/">https://www.myip.com/</a></li>
        <li><a href="https://www.ip-adress.com/">https://www.ip-adress.com/</a></li>
        <li><a href="https://www.whatismyip.com/">https://www.whatismyip.com/</a></li>
      </ul>

      <TitleSection>Step 6: Explore more options</TitleSection>

      The command line provides a <code>--help</code> option that will show you all the available commands:

      <Code>mytmpvpn --help</Code>

      You can also read the <Link href="/faq">FAQ</Link> or get <Link href="/support">support</Link>

    </WideView>
  )
}
