import * as React from 'react'
import { MainTitle, WideView } from '../Common'
import { Link, Text, View } from '@aws-amplify/ui-react'

export default function Team() {
  return (
    <WideView>
      <MainTitle>Team</MainTitle>
      <Text>
        MyTmpVpn has been designed and is being developed and operated 
        by <Link isExternal={true} href="https://linkedin.com/in/pierrevigneras" target="_blank">Pierre Vigneras</Link>.
      </Text>
    </WideView>
  )
}
