import * as React from 'react'

import { Link, Text } from '@aws-amplify/ui-react'
import { MainTitle, WideView } from '../Common'

export default function MakeMoney() {
  return (
    <WideView>
      <MainTitle>Make Money</MainTitle>
      You can operate your own MyTmpVpn service for your friends and family, for training purposes, and/or for a new business. 
      MyTmpVpn will be open-sourced by 2024, and instructions will be given. Meanwhile, 
      if you're willing to do so, <Link href="/contactus">contact us</Link> and we will help you with setup and training.
    </WideView>
  )
}
