import React from 'react'

import { View, Button } from '@aws-amplify/ui-react'


import { MyTmpVpnClient } from '@mytmpvpn/mytmpvpn-client/client'
import * as models from '@mytmpvpn/mytmpvpn-common/models/vpn'

import { reactUpdateVpnWithMetrics } from '../Common'
import { useErrorContext } from '../../ErrorContext'
import { VpnWithMetrics } from '../../pages/Home'

function downloadQrConfig(client: MyTmpVpnClient, setDownloadQrConfigInProgress: any,
  vpn: models.Vpn, setError: any) {


  setDownloadQrConfigInProgress(true)

  client.getVpnQrConfig(vpn.vpnId)
    .then(b64 => {
      // Open a new window to display the qrcode PNG file
      const win = window.open('', '_blank')
      if (!win) {
        throw new Error('Could not open new window')
      }
      win.document.write(`<center>
        <h1>QR Code for VPN ${vpn.vpnId}</h1>
        <p>
          Scan this QR code with your mobile phone to connect to your VPN from the Wireguard open-source application.
        </p>
        <img src="data:image/png;base64,${b64}"/>
        </center>`)
      win.document.close()
      win.focus()
    }).catch(e => {
      console.error(JSON.stringify(e))
      setError(e)
    }).finally(() => {
      setDownloadQrConfigInProgress(false)
    })
}

function downloadConfig(client: MyTmpVpnClient, setDownloadConfigInProgress: any,
  vpn: models.Vpn, setError: any) {

  setDownloadConfigInProgress(true)

  client.getVpnConfig(vpn.vpnId)
    .then(b64 => {
      const vpnConfig = atob(b64)
      // Ask end-user what file to write the content of vpnConfig to
      // Set MIME type to application/octet-stream to prevent some browsers (Chrome on Android at least) to rename 
      // the file with an '.txt' extension.
      const url = window.URL.createObjectURL(new Blob([vpnConfig], { type: 'application/octet-stream' }))
      // Create a link to the file
      const a = document.createElement('a')
      a.href = url
      a.download = `${models.vpnIdToWgFileName(vpn.vpnId)}.conf`
      a.click()
    }).catch(e => {
      console.error(JSON.stringify(e))
      setError(e)
    }).finally(() => {
      setDownloadConfigInProgress(false)
    })
}

function deleteVpn(client: MyTmpVpnClient, setDeleteInProgress: any,
  vpnWithMetrics: VpnWithMetrics, setVpnsWithMetrics: any, setError: any) {

  setDeleteInProgress(true)

  client.deleteVpn(vpnWithMetrics.vpn.vpnId)
    .then(deleteResponse => {
      // Copy the given vpns array, and replace the deleted vpn with a new one
      setVpnsWithMetrics((prevVpnsWithMetrics: VpnWithMetrics[]) => {
        return reactUpdateVpnWithMetrics(prevVpnsWithMetrics, deleteResponse)
      })
      console.log(`Waiting for vpn to be in Deleted state ${JSON.stringify(deleteResponse.vpn)}`)
      return client.waitUntilVpnStateIs(deleteResponse.vpn.vpnId, models.VpnState.Deleted)
    }).then(vpn => {
      console.log(`Vpn is now in Deleted state ${JSON.stringify(vpn)}`)
      setVpnsWithMetrics((prevVpnsWithMetrics: VpnWithMetrics[]) => {
        return reactUpdateVpnWithMetrics(prevVpnsWithMetrics, {
          vpn: vpn, metrics: vpnWithMetrics.metrics
        })
      })
    }).catch(e => {
      setError(e)
    }).finally(() => {
      setDeleteInProgress(false)
    })
}

export function Actions({ client, vpnWithMetrics, vpnsWithMetrics, setVpnsWithMetrics }: {
  client: MyTmpVpnClient, vpnWithMetrics: VpnWithMetrics,
  vpnsWithMetrics: VpnWithMetrics[], setVpnsWithMetrics: any
}) {

  const [deleteInProgress, setDeleteInProgress] = React.useState<boolean>(false)
  const [downloadConfigInProgress, setDownloadConfigInProgress] = React.useState<boolean>(false)
  const [downloadQrConfigInProgress, setDownloadQrConfigInProgress] = React.useState<boolean>(false)
  const { error, setError } = useErrorContext()

  return (
    <View>
      <Button
        isLoading={downloadConfigInProgress}
        onClick={() => downloadConfig(client, setDownloadConfigInProgress, vpnWithMetrics.vpn, setError)}>
        Config
      </Button>
      <Button
        isLoading={downloadQrConfigInProgress}
        onClick={() => downloadQrConfig(client, setDownloadQrConfigInProgress, vpnWithMetrics.vpn, setError)}>
        QRCode
      </Button>
      <Button
        isLoading={deleteInProgress}
        onClick={() => deleteVpn(client, setDeleteInProgress, vpnWithMetrics, setVpnsWithMetrics, setError)}>
        Delete
      </Button>
    </View>
  )
}
