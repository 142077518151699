import * as React from 'react'

import { Link, Message, View } from '@aws-amplify/ui-react'
import { Emph, MainTitle, Ref, TitleSection, WideView } from '../Common'
import { TableOfContents } from './TableOfContents'


export default function Doc() {
  return (
    <WideView>
      <center>
        <TableOfContents headingsCommaSeparatedList='h3' />
      </center>

      <MainTitle>MyTmpVpn Documentation</MainTitle>

      <Message colorTheme='info'>
        <View>
          This is the documentation for the <Link href='/'>MyTmpVpn</Link> project.
          If you are looking for the command line interface (mytmpvpn-cli),
          see the <Link href="/doc-cli">mytmpvpn-cli documentation</Link>
        </View>
      </Message>

      MyTmpVpn is a VPN service that is <Link isExternal={true} href="https://gitlab.com/mytmpvpn">open-source</Link>,
      secured by WireGuard and AWS.

      <TitleSection>Tenets</TitleSection>
      <ul>
        <li><Emph>Open</Emph>: as in <Link isExternal={true} href="https://en.wikipedia.org/wiki/Open_source">open-source</Link>: 
            the project is hosted on GitLab <Ref>https://gitlab.com/mytmpvpn</Ref></li>
        <li><Emph>Secured</Emph>: thanks to WireGuard <Ref>https://gitlab.com/mytmpvpn/mytmpvpn-backend/-/blob/main/ami/install-wireguard.sh?ref_type=heads#L14</Ref> and AWS <Ref>https://gitlab.com/mytmpvpn/mytmpvpn-backend/-/blob/main/src/lambda/provisioner/provision.ts?ref_type=heads#L69</Ref>;</li>
        <li><Emph>Subscription-free</Emph>: you pay upfront for a given amount of resources your VPN can consume <Ref>https://gitlab.com/mytmpvpn/mytmpvpn-backend/-/blob/main/src/lambda/provisioner/monitoring.ts?ref_type=heads#L58</Ref>;</li>
        <li><Emph>Spam-free</Emph>: we don't send spam, we don't sell any data;</li>
        <li><Emph>Cookie-free</Emph>: we do not use cookie; only local-storage for authentication <Ref>https://docs.amplify.aws/react/build-a-backend/auth/concepts/tokens-and-credentials/#update-your-token-saving-mechanism</Ref>;</li>
        <li><Emph>Logs-free</Emph>: by default we don't use logs, though you might activate them for debugging purposes;[TODO: ref]</li>
        <li><Emph>SSH-free</Emph>: the server that runs your VPN does not run any ssh server <Ref>https://gitlab.com/mytmpvpn/mytmpvpn-backend/-/commit/bdc29438caf50d28e9608cc3b7e941bb1b8e1844</Ref></li>
      </ul>

      Need more information? Read the <Link href="/faq">FAQ</Link> or get <Link href="/support">support</Link>.

    </WideView>
  )
}
