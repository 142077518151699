import React from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'

export function RequireAuth ({ children }: { children: any }) {
  const location = useLocation()
  const { authStatus } = useAuthenticator((context) => [context.authStatus])
  if (authStatus !== 'authenticated') {
    return <Navigate to="/login" state={{ from: location }} replace />
  }
  return children
}
