import React from 'react'
import { Button, Card, Label, Input, Text, Flex, Image, StepperField, Table, TableBody, TableCell, TableHead, TableRow, View, useAuthenticator, SliderField, Divider, Icon, CheckboxField, TextField } from '@aws-amplify/ui-react'
import { MyTmpVpnClient, RegionInfo } from '@mytmpvpn/mytmpvpn-client/client'
import { Vpn, VpnConfig, VpnState, VpnType } from '@mytmpvpn/mytmpvpn-common/models/vpn'
import { MainSubTitle, MainTitle, ShortCenteredTextView, WideView, reactUpdateVpnWithMetrics } from '../components/Common'
import { useErrorContext } from '../ErrorContext'
import { useNavigate } from 'react-router-dom'
import { VpnWithMetrics } from './Home'
import { PEANUTS_CONFIG } from '@mytmpvpn/mytmpvpn-common/models/peanuts'
import { formatDuration, intervalToDuration, format, formatDistance, formatISO } from 'date-fns'

function createVpn(client: MyTmpVpnClient,
    region: RegionInfo,
    config: VpnConfig,
    setInProgress: any,
    setVpnsWithMetrics: any,
    setError: any) {

    console.log(`Setting inProgress to true for ${JSON.stringify(region)}`)
    setInProgress(true)
    client.createVpn(region.name, config).then(vpn => {
        console.log(`New vpn created: ${JSON.stringify(vpn)}`)
        setVpnsWithMetrics((prevVpns: VpnWithMetrics[]) => {
            return [{ vpn: vpn, metrics: undefined }, ...prevVpns]
        })
        console.log(`Waiting for vpn to be in Running state: ${JSON.stringify(vpn)}`)
        return client.waitUntilVpnStateIs(vpn.vpnId, VpnState.Running)
    }).then(vpn => {
        console.log(`Vpn is now in Running state: ${JSON.stringify(vpn)}`)
        return client.getVpn(vpn.vpnId).then(response => {
            setVpnsWithMetrics((prevVpnWithMetrics: VpnWithMetrics[]) => {
                // Time has flied in between, the previous array might contain other 
                // newly created Vpns.
                return reactUpdateVpnWithMetrics(prevVpnWithMetrics, response)
            })
        })
    }).catch(e => {
        setError(e)
    }).finally(() => {
        setInProgress(false)
    })
}

function Actions({ client, region, config, setVpnsWithMetrics }: { client: MyTmpVpnClient, region: RegionInfo, config: VpnConfig, setVpnsWithMetrics: any }) {
    const [inProgress, setInProgress] = React.useState<boolean>(false)
    const { error, setError } = useErrorContext()

    return (
        <Button
            isLoading={inProgress}
            onClick={() => createVpn(client, region, config, setInProgress, setVpnsWithMetrics, setError)}>
            Create
        </Button>
    )
}

function formatDeleteAfter(minutes: number) {
    const duration = intervalToDuration({ start: 0, end: minutes * 60 * 1000 }) // Convert to milliseconds
    return `${formatDuration(duration)}`
}

function formatMaxPeanuts(peanuts: number) {
    if (peanuts === 0) { 
        return `all peanuts in your account`
    }
    return `a maximum of ${peanuts} peanuts`
}

export function Regions({ client, balance, setVpnsWithMetrics }: { client: MyTmpVpnClient, balance: number, setVpnsWithMetrics: any }) {
    const defaultMaxPeanuts = 0 // 0 means all in account
    const sliderMaxPeanuts = isNaN(balance) ? PEANUTS_CONFIG.max : balance
    const defaultMaxDeleteAfter = 60 * 4 // 4 hours by default
    const [maxPeanuts, setMaxPeanuts] = React.useState<number>(defaultMaxPeanuts)
    const [deleteAfter, setDeleteAfter] = React.useState(defaultMaxDeleteAfter)
    const [regions, setRegions] = React.useState<RegionInfo[]>([])
    const { error, setError } = useErrorContext()
    const navigate = useNavigate()
    const { authStatus } = useAuthenticator((context) => [context.authStatus])

    React.useEffect(() => {
        client.listRegionsDetailed()
            .then(result => {
                setRegions(result)
            })
            .catch(e => setError(e))
    }, [])

    return (
        <WideView>
            <MainTitle>Regions</MainTitle>
            <MainSubTitle>Configuration</MainSubTitle>
            <Card >
                <Flex direction={"row"} wrap="nowrap" justifyContent="center" alignItems="baseline" gap="2em">
                    <Card>
                        <Flex direction={"column"}>
                        <SliderField
                                id='deleteAfter'
                                label="delete After:"
                                defaultValue={defaultMaxDeleteAfter}
                                min={5}
                                max={24 * 60}
                                step={5}
                                onChange={setDeleteAfter}
                                formatValue={formatDeleteAfter}
                            />
                            <Text>
                                <b>Running vpn can be auto-deleted after a given time.</b><br />
                                If a vpn is created at {new Date().toLocaleString()} it will be deleted after: {new Date(new Date().getTime() + deleteAfter * 60 * 1000).toLocaleString()}</Text>
                            <Divider />
                        </Flex>
                    </Card>
                    <Card>
                        <Flex direction={"column"}>
                        <SliderField
                                label="Max peanuts:"
                                min={0}
                                max={sliderMaxPeanuts}
                                step={1}
                                defaultValue={defaultMaxPeanuts}
                                onChange={(value) => setMaxPeanuts(value)}
                                formatValue={formatMaxPeanuts}
                            />
                            <Text>
                                <b>Running vpn consumes peanuts.</b><br />
                                New vpn created with above configuration will be auto-deleted, after {formatMaxPeanuts(maxPeanuts)} have been consumed.
                            </Text>
                            <Divider />
                        </Flex>
                    </Card>
                </Flex>
            </Card>
            <Table
                caption=""
                highlightOnHover={false}
            >
                <TableHead>
                    <TableRow>
                        <TableCell as="th">Name</TableCell>
                        <TableCell as="th">City</TableCell>
                        <TableCell as="th">Country</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        regions && regions.sort((a, b) => a.name < b.name ? -1 : 1)
                            .map((region, index) => (
                                <TableRow key={index}>
                                    <TableCell>{region.name}</TableCell>
                                    <TableCell>{region.city}</TableCell>
                                    <TableCell>
                                        <View>
                                            <Image
                                                alt={region.country}
                                                src={`https://icons.iconarchive.com/icons/osiris/world-flags/16/00-cctld-${region.cctld}-icon.png`}
                                                height="16"
                                                width="16"
                                                marginRight={"0.5em"}
                                            />

                                            {region.country}
                                        </View>
                                    </TableCell>
                                    <TableCell>
                                        {authStatus === 'authenticated' ?
                                            <Actions
                                                client={client}
                                                region={region}
                                                // Add 30 seconds to the maxVpnDuration to account for the round trip of the message
                                                config={{ maxPeanuts, type: VpnType.WireGuard, deleteAfter: deleteAfter * 60}}
                                                setVpnsWithMetrics={setVpnsWithMetrics} />
                                            : <Button onClick={() => navigate('/pricing')}>Create</Button>}
                                    </TableCell>
                                </TableRow>
                            ))
                    }
                </TableBody>
            </Table >
        </WideView >
    )
}
