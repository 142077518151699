import React from 'react'

import { useAuthenticator, View, Link, Flex, Button, Loader } from '@aws-amplify/ui-react'
import { MyTmpVpnClient } from '@mytmpvpn/mytmpvpn-client/client'

import { WideView } from '../components/Common'
import { useErrorContext } from '../ErrorContext'
import { Instructions } from '../components/static/Instructions'
import { PeanutsBalance } from '../account/PeanutsBalance'
import { VpnWithMetrics, listVpnsPage } from './Home'
import { Vpns } from '../components/vpns/Vpns'

function refresh(client: MyTmpVpnClient,
  setBalance: any,
  setRefreshInProgress: any,
  setVpnsWithMetrics: any,
  pageTokens: any,
  setPageTokens: any,
  setCurrentPageIndex: any,
  setHasMorePages: any,
  setError: any,
) {

  setRefreshInProgress(true)
  setCurrentPageIndex(1)
  client.getPeanutsBalance()
    .then(balance => {
      console.log(`Balance: ${balance}`)
      setBalance(balance)
    }).then(() => {
      listVpnsPage(client,
        setVpnsWithMetrics,
        setRefreshInProgress,
        pageTokens,
        setPageTokens,
        1,
        setHasMorePages,
        setError)
    }).catch(e => {
      setError(e)
      setRefreshInProgress(false)
    })
}

function DashboardInternal({ client,
  balance,
  setBalance,
  vpnsWithMetrics,
  setVpnsWithMetrics,
  pageTokens,
  setPageTokens,
  currentPageIndex,
  setCurrentPageIndex,
  hasMorePages,
  setHasMorePages }: {
    client: MyTmpVpnClient,
    balance: number,
    setBalance: any,
    vpnsWithMetrics: VpnWithMetrics[],
    setVpnsWithMetrics: any,
    pageTokens: string[],
    setPageTokens: any,
    currentPageIndex: number,
    setCurrentPageIndex: any,
    hasMorePages: boolean,
    setHasMorePages: any
  }) {

  const [refreshInProgress, setRefreshInProgress] = React.useState<boolean>(false)
  const { error, setError } = useErrorContext()

  return (
    <View>
      <Instructions />
      <Flex justifyContent={'center'} direction='row' gap='2'>
        <PeanutsBalance balance={balance} />
        <Button isLoading={refreshInProgress} onClick={() => refresh(client,
          setBalance,
          setRefreshInProgress,
          setVpnsWithMetrics,
          pageTokens,
          setPageTokens,
          setCurrentPageIndex,
          setHasMorePages,
          setError)}>Refresh</Button>
      </Flex>
      <Vpns client={client}
        vpnsWithMetrics={vpnsWithMetrics}
        setVpnsWithMetrics={setVpnsWithMetrics}
        pageTokens={pageTokens}
        setPageTokens={setPageTokens}
        currentPageIndex={currentPageIndex}
        setCurrentPageIndex={setCurrentPageIndex}
        hasMorePages={hasMorePages}
        setHasMorePages={setHasMorePages}
        setError={setError}
      />
    </View>
  )
}

export function Dashboard({ client,
  balance,
  setBalance,
  vpnsWithMetrics,
  setVpnsWithMetrics,
  pageTokens,
  setPageTokens,
  currentPageIndex,
  setCurrentPageIndex,
  hasMorePages,
  setHasMorePages }: {
    client: MyTmpVpnClient,
    balance: number,
    setBalance: any,
    vpnsWithMetrics: VpnWithMetrics[],
    setVpnsWithMetrics: any,
    pageTokens: string[],
    setPageTokens: any,
    currentPageIndex: number,
    setCurrentPageIndex: any,
    hasMorePages: boolean,
    setHasMorePages: any
  }) {

  const { authStatus } = useAuthenticator((context) => [context.authStatus])

  return (
    <WideView>
      {authStatus === 'authenticated' ?
        <DashboardInternal
          client={client}
          balance={balance}
          setBalance={setBalance}
          vpnsWithMetrics={vpnsWithMetrics}
          setVpnsWithMetrics={setVpnsWithMetrics}
          pageTokens={pageTokens}
          setPageTokens={setPageTokens}
          currentPageIndex={currentPageIndex}
          setCurrentPageIndex={setCurrentPageIndex}
          hasMorePages={hasMorePages}
          setHasMorePages={setHasMorePages}
        /> :
        <Link href="/login">Please authenticate</Link>}
    </WideView>
  )
}