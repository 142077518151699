import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Badge, Flex } from '@aws-amplify/ui-react'

export function PeanutsBalance({ balance }: { balance: number }) {
  const navigate = useNavigate()

  return (
    <Flex direction='row' alignItems='center'>
      <Badge
        size='large'
        variation={balance.valueOf() > 0 ?
          balance.valueOf() > 5 ? 'info' :
            'warning' :
          'error'}>Peanuts Balance: {balance.valueOf()}</Badge>
      <Button onClick={() => navigate('/pricing')}>Buy More</Button>
    </Flex>
  )
}
