import * as React from 'react'

import { Text, View } from '@aws-amplify/ui-react'
import { MainTitle, WideView } from '../Common'

export default function ContactUs() {
  return (
    <WideView>
      <MainTitle>Contact Us</MainTitle>
      <Text>
        Best way to contact us, is by email:
        <ul>
          <li>Support: <a href="mailto:support@mytmpvpn.com">support@mytmpvpn.com</a></li>
          <li>General: <a href="mailto:general@mytmpvpn.com">general@mytmpvpn.com</a></li>
        </ul>
      </Text>
    </WideView>
  )
}
