import React from 'react'
import { useAuthenticator, View, Link, Flex } from '@aws-amplify/ui-react'
import { MainTitle, WideView } from '../components/Common'
import { AccountSettings } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';


function PasswordChangeInternal() { 
  const navigate = useNavigate()

  const handleSuccess = () => {
    alert('Password is successfully changed!')
    navigate('/')
  }

  return (
    <View>
      <Flex direction={'column'} justifyContent={'center'} alignItems={'center'}>
        <AccountSettings.ChangePassword onSuccess={handleSuccess} />
      </Flex >
    </View>
  )
}

export function PasswordChange() {
  const { route } = useAuthenticator((context) => [context.route])

  return (
    <WideView>
      <MainTitle>Change Your Password</MainTitle>
      {route === 'authenticated' ? <PasswordChangeInternal /> : <Link href="/login">Please authenticate</Link>}
    </WideView>
  )
}