import React, { useEffect } from 'react'
import { PasswordChange } from './auth/PasswordChange'
import { RequireAuth } from './RequireAuth'
import { Login } from './auth/Login'
import { Home } from './pages/Home'
import { Layout } from './components/Layout'
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import Team from './components/static/Team'
import PrivacyPolicy from './components/static/PrivacyPolicy'
import TermsOfUse from './components/static/TermsOfUse'
import History from './components/static/History'
import ContactUs from './components/static/ContactUs'
import Pricing from './pages/Pricing'
import Doc from './components/static/Doc'
import Support from './components/static/Support'
import Faq from './components/static/Faq'

import { type MyTmpVpnClient } from '@mytmpvpn/mytmpvpn-client/client'
import { ErrorProvider } from './ErrorContext'
import { Unregister } from './auth/Unregister'
import Contribute from './components/static/Contribute'
import MakeMoney from './components/static/MakeMoney'
import { NotFound } from './components/static/NotFound'
import { Issue } from './components/static/Issue'
import DocCli from './components/static/DocCli'

export function MyRoutes({ client }: { client: MyTmpVpnClient }) {
  
  return (
    <BrowserRouter>
      <ErrorProvider>
        <Routes>
          <Route path='*' element={<NotFound />} />
          <Route path="/" element={<Layout />}>
            {/* Header  */}
            <Route path="/pricing" element={<Pricing client={client} />} />
            {/* <Route path="/doc" element={<Doc />} /> */}
            <Route path="/contribute" element={<Contribute />} />
            <Route path="/makemoney" element={<MakeMoney />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/doc" element={<Doc />} />
            <Route path="/doc-cli" element={<DocCli />} />
            <Route path="/issue" element={<Issue />} />
            <Route path="/support" element={<Support />} />
            <Route path="/password" element={
              <RequireAuth>
                <PasswordChange />
              </RequireAuth>
            } />
            <Route path="/unregister" element={
              <RequireAuth>
                <Unregister client={client} />
              </RequireAuth>
            } />
            <Route path="/login" element={<Login client={client} initialState='signIn' />} />
            <Route path="/signup" element={<Login client={client} initialState='signUp' />} />
            {/* Main content */}
            <Route index element={<Home client={client} />} />
            {/* Footer  */}
            <Route path="/team" element={<Team />} />
            <Route path="/history" element={<History />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfUse />} />
          </Route>
        </Routes>
      </ErrorProvider>
    </BrowserRouter >
  )
}
